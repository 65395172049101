import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/AboutUs';
import ContactUs from './Components/ContactUs/ContactUs';
import SGX_Nifty from './Components/SGX/SGX_Nifty';
import Privacy_Policy from './Components/MmiscellaneousPages/Privacy_Policy';
import Terms_and_Conditions from './Components/MmiscellaneousPages/terms_and_conditions';
import Acc_Delete from './Components/MmiscellaneousPages/acc_delete';
import SignupForm from './Components/Signup/Signup';
import Login from './Components/Login/Login';
import Upstox from './Components/Upstox/Upstox';
import Getin from './Components/GetIn/Getin';
import Blank from './Components/Blank/Blank';
import Zerodha from './Components/Zerodha/Zerodha';
import Blogs from './Components/Blogs/Blogs';
import Fyers from './Components/Fyers/Fyers';
import ATO from './Components/ATO/ATO';
import Interest from './Components/Blogs/Interest/Interest';
import NotFound from './Components/NotFound/NotFound';
import reportWebVitals from './reportWebVitals';
import Lko_to_Banglore from './Components/Blogs/Lko_to_Banglore/Lko_to_Banglore';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Privacy_Policy_Web from './Components/MmiscellaneousPages/Privacy_Policy_web';
import Disclaimer_Web from './Components/MmiscellaneousPages/disclaimer_web';
import Terms_and_Conditions_Web from './Components/MmiscellaneousPages/terms_and_conditions_web';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sgx_nifty" element={<SGX_Nifty />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/privacy_policy" element={<Privacy_Policy />} />
        <Route path="/account_delete" element={<Acc_Delete />} />
        <Route path="/upstox" element={<Upstox />} />
        <Route path="/zerodha" element={<Zerodha />} />
        <Route path="/fyers" element={<Fyers />} />
        <Route path="/fyers/fyers" element={<Fyers />} />
        {/* <Route path="/angel_one" element={<Blank />} /> */}
        <Route path="/getin" element={<Getin />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/ato" element={<ATO />} />
        <Route path="/simple_interest_vs_compont_interest" element={<Interest />} />
        <Route path="/how_to_reach_zerodha_office_bangalore" element={<Lko_to_Banglore />} />

        <Route path="/privacy_policy_web" element={<Privacy_Policy_Web />} />
        <Route path="/disclaimer_web" element={<Disclaimer_Web />} />
        <Route path="/Terms_and_conditions_web" element={<Terms_and_Conditions_Web />} />

        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/terms_and_conditions" element={<Terms_and_Conditions />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode >
);

reportWebVitals();
