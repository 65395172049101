import React from "react";
import Navbar from "../../../Widgets/Navbar/Navbar";
import Footer from "../../../Widgets/Footer/Footer";

const Interest = () => {
  return (
    <div className="min-h-screen bg-[#f3f3f3]">
      <div className="h-[60px]" />
      <Navbar />

      <div className="w-full px-4 md:px-8 lg:px-16 max-w-[1600px] mx-auto bg-white">
        {/* Title Section */}
        <div 
          className="my-4 md:my-6 p-4 md:p-6 text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-center text-white rounded-xl bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: `url('https://64.media.tumblr.com/9b21c9ffae9b31777331159753fe2f23/tumblr_ontrlf7Uem1uzwgsuo1_400.gif')`,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)'
          }}
        >
          What is the Interest, Simple Interest and compound interest?
        </div>

        {/* Main Content */}
        <div className="px-4 md:px-8 lg:px-16 space-y-8">
          {/* Interest Definition */}
          <div className="space-y-4">
            <p>
              <strong className="block mb-2">Interest - Finance</strong> 
              में इंटरेस्ट एक पेमेंट है जो कर्ज लेने वाले को देना पड़ता है और अगर किस ने बैंक में डिपॉज़िट किया है तो उसे डिपॉज़िट यानि मूलधन पर जो पैसा मिलता है उसे इंटरेस्ट या ब्याज कहते हैं ।
            </p>
            <p>
              दुसरे शब्दों में कहें तो इंटरेस्ट, पैसे को ब्याज पर लेने या बैंक में डिपॉज़िट करने की इनाम राशि है ।
            </p>
          </div>

          {/* Example Section */}
          <div className="mt-8 space-y-4">
            <p>
              <strong className="block mb-2">उदहारण</strong>
              मान लीजिये किसी बैंक में आपने 1 लाख रूपये का फिक्स डिपॉज़िट किया उस कुछ राशि आपको इनाम के तौर पर दी गयी जैसे 7000 रूपये तो यह 7000 की राशि आपका ब्याज होगा और उसकी दर 7% के हिसाब से होगी हो गई है।
            </p>
          </div>

          {/* Types of Interest */}
          <div className="space-y-6">
            <strong className="block text-lg md:text-xl">इंटरेस्ट दो प्रकार का होता है</strong>
            
            <div className="space-y-4">
              <p>
                <strong className="block mb-2">(Simple Interest)</strong>
                इसे हिंदी में साधारण ब्याज कहा जाता है, यह एक सरल गणना करने तरीका है मूलधन पर लगे हुए ब्याज का, इसका इस्तेमाल आज भी बैंकिंग, नॉन बैंकिंग सेक्टर में किया जाता है जैसे फिक्स डिपाजिट जिनकी अवधि १ वर्ष से कम, सर्टिफिकेट डिपाजिट ,इत्यादि में होता है |
              </p>
              
              <p>
                <strong className="block mb-2">(Compound Interest)</strong>
                आपने बहुत सी बार पुराने लोगों से सुना होगा ब्याज पर ब्याज इसे ही कंपाउंड इंटरेस्ट या हिंदी में चक्रवृद्धि ब्याज़ कहा जाता है, बहुत से विद्वानों का मानना है कि यह दुनिया का 8वा अजूबा है |
              </p>
            </div>
          </div>

          {/* Einstein Quote */}
          <div className="my-8 text-center space-y-4">
            <p>अल्बर्ट आइंस्टीन ने एक समय कहा था-</p>
            <p className="text-lg md:text-xl font-bold">
              "ब्रह्मांड में सबसे शक्तिशाली बॉल चक्रवृद्धि ब्याज है"
            </p>
            <img 
              src="/blogs_files/alb.png" 
              alt="Albert Einstein"
              className="w-full max-w-2xl mx-auto h-auto rounded-lg shadow-md"
            />
          </div>

          {/* Additional Information */}
          <div className="space-y-6">
            <p>
              जहाँ आपको साधारण ब्याज में सिर्फ मूलधन पर ही ब्याज मिलता है वहीँ चक्रवृद्धि ब्याज़ में आपको न सिर्फ आपके मूलधन बल्कि आपके मूलधन पर लगे हुए ब्याज से संचित हुई राशि (मिश्रधन) पर भी ब्याज लगता है इसका उपयोग बैंकिंग, नॉन बैंकिंग सेक्टर में बहुत सी जगहों पर किया जाता है जैसे लम्बे समय के लिए होम लोन, पर्सनल लोन कार लोन इत्यादि में ।
            </p>
          </div>

          {/* Formula Section */}
          <div className="space-y-6">
            <strong className="block text-lg md:text-xl">चक्रवृद्धि ब्याज का सूत्र</strong>
            <img 
              src="/blogs_files/for.png" 
              alt="Formula"
              className="w-full max-w-lg mx-auto h-auto"
            />
            <div className="space-y-2">
              <p>जहाँ</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>A - राशि</li>
                <li>P - मूलधन</li>
                <li>n - समय</li>
                <li>r - ब्याज दर</li>
                <li>t - समय है</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="h-[120px]" />
      </div>
      <Footer />
    </div>
  );
};


export default Interest;
